import { Box, Container, HStack, Stack, Text, VStack, Center, SimpleGrid } from '@chakra-ui/layout';
import { Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/tabs';
import { Link, useDisclosure } from '@chakra-ui/react';
import Donate from './Donate';
import Airdrop from './Airdrop';
import Tokenomics from './Tokenomics';
import contractAbi from './abis/resiquant-abi.json';
import { ConnectButton, useConnectModal } from '@rainbow-me/rainbowkit';
import { Button } from '@chakra-ui/button';
import ResiquantLogo from './assets/resiquant.svg';
import { Image } from '@chakra-ui/image';
import { Icon } from '@chakra-ui/icon';
import { FaDiscord, FaTelegramPlane } from 'react-icons/fa';
import { BsTwitterX } from 'react-icons/bs';
import { useAccount, useReadContract } from 'wagmi';
import React, { useEffect, useState } from 'react';
import { getFirestore, doc, runTransaction, Transaction, getDoc } from 'firebase/firestore';
import { Input } from '@chakra-ui/input';
import { initializeApp } from 'firebase/app';
import { useToast } from '@chakra-ui/toast';

const firebaseConfig = {
  apiKey: 'AIzaSyAoQxS6zUsSboyKP1CCCwpcS27xWLEI2zI',
  authDomain: 'resiquant-2c844.firebaseapp.com',
  projectId: 'resiquant-2c844',
  storageBucket: 'resiquant-2c844.appspot.com',
  messagingSenderId: '177470873865',
  appId: '1:177470873865:web:cc0432a2d9f0d4ef4f33d8',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const resiquantAbi = contractAbi;

export const donationAddress = '0x9D45227a7e8E0A2e483754D29Ffe9005535CFe20';

async function getCounterValue() {
  const counterRef = doc(db, 'counters', 'codes');
  try {
    const docSnap = await getDoc(counterRef);
    if (docSnap.exists()) {
      return docSnap.data().count; // Return the count value
    } else {
      return 0; // Counter document does not exist
    }
  } catch (error) {
    return 0; // Return 0 or handle error appropriately
  }
}

const App = () => {
  const { address } = useAccount();
  //const { hasCopied, onCopy } = useClipboard(donationAddress);
  const [code, setCode] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [counterValue, setCounterValue] = useState(1000);

  const { isOpen, onToggle } = useDisclosure();

  const { data: hasDonated } = useReadContract({
    address: donationAddress,
    abi: resiquantAbi,
    functionName: 'hasDonated',
    args: [address],
  });
  const { openConnectModal } = useConnectModal();

  const toast = useToast();

  const checkCode = async () => {
    try {
      await runTransaction(db, async (transaction: Transaction) => {
        const codeRef = doc(db, 'codes', code);
        const codeSnap = await transaction.get(codeRef);

        if (!codeSnap.exists()) {
          throw new Error('Code does not exist.'); // This will be caught by the catch block below
        }

        // Delete the code document

        // Decrement the counter
        const counterRef = doc(db, 'counters', 'codes');
        const counterSnap = await transaction.get(counterRef);
        const currentCount =
          counterSnap.exists() && counterSnap.data().count ? counterSnap.data().count : 0;
        if (currentCount <= 0) {
          throw new Error('Counter is already at 0.');
        }
        transaction.delete(codeRef);
        transaction.update(counterRef, { count: currentCount - 1 });

        // Set authentication state and local storage
        setIsAuthenticated(true);
        localStorage.setItem('resiquant-user', code);
      });

      toast({
        title: 'Code Verified',
        description: 'You have been successfully authenticated.',
        status: 'success',
      });
    } catch (e) {
      setIsAuthenticated(false);
      toast({
        title: 'Invalid/Redeemed Code',
        status: 'error',
      });
    }
  };

  useEffect(() => {
    const existingUser = localStorage.getItem('resiquant-user');
    if (existingUser && !isAuthenticated) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (address && hasDonated && !isAuthenticated) {
      setIsAuthenticated(true);
      localStorage.setItem('resiquant-user', address);
    }
  }, [address, hasDonated]);

  useEffect(() => {
    // set interval
    getCounterValue().then(value => setCounterValue(value));
    const interval = setInterval(() => {
      getCounterValue().then(value => setCounterValue(value));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <VStack
        position='absolute'
        w='100%'
        h='100%'
        bg='whiteAlpha.100'
        backdropFilter='blur(2px)'
        zIndex='9'
        display={{ base: isOpen ? 'flex' : 'none', md: 'none' }}
      >
        <Box w='100%' px='20px' position='absolute' top='20'>
          <VStack
            w='100%'
            p='1'
            bg='gray.800'
            borderRadius='lg'
            textTransform='uppercase'
            textAlign='center'
            spacing='2'
            fontSize='sm'
          >
            <Center
              w='100%'
              h='40px'
              borderRadius='lg'
              border='1px solid'
              borderColor='transparent'
              _hover={{ bg: 'gray.900', borderColor: 'gray.700', textDecoration: 'none' }}
              as={Link}
              href='https://www.resiquant.com/'
            >
              <Text>Home</Text>
            </Center>
            <Center
              w='100%'
              h='40px'
              borderRadius='lg'
              border='1px solid'
              borderColor='transparent'
              _hover={{ bg: 'gray.900', borderColor: 'gray.700', textDecoration: 'none' }}
              as={Link}
              href='https://www.resiquant.com/technical-paper'
            >
              <Text>Technical Overview</Text>
            </Center>
            <Center
              w='100%'
              h='40px'
              borderRadius='lg'
              border='1px solid'
              borderColor='transparent'
              _hover={{ bg: 'gray.900', borderColor: 'gray.700', textDecoration: 'none' }}
              as={Link}
              href='https://www.resiquant.com/roadmap'
            >
              <Text>Roadmap</Text>
            </Center>
            <Button
              textTransform='uppercase'
              fontWeight='light'
              bg='white'
              w='100%'
              color='gray.900'
              _hover={{ bg: 'gray.900', color: 'white' }}
              _active={{}}
              fontSize='sm'
            >
              Early Access
            </Button>
          </VStack>
        </Box>
      </VStack>
      <Container maxW='1600px' px={{ base: '20px', lg: '40px' }}>
        <VStack w='100%' align='start' pt='4'>
          <HStack
            zIndex='999'
            textTransform='uppercase'
            w='100%'
            bg='gray.800'
            border='1px solid'
            borderColor='gray.700'
            pl='16px'
            pr='1'
            py='4px'
            borderRadius='lg'
            justify='space-between'
          >
            <HStack as={Link} href='https://www.resiquant.com/' _hover={{ textDecoration: 'none' }}>
              <Image src={ResiquantLogo} alt='R' h='23px' />
              <Text
                letterSpacing='1px'
                fontSize='26px'
                fontFamily='Aeonik'
                textTransform='uppercase'
              >
                Resiquant
              </Text>
            </HStack>
            <Center
              cursor='pointer'
              bg='white'
              boxSize='38px'
              borderRadius='8px'
              onClick={onToggle}
              display={{ base: 'flex', md: 'none' }}
            >
              <VStack w='100%' position='relative'>
                <Box
                  h='1px'
                  w={isOpen ? '65%' : '40%'}
                  bg='gray.700'
                  transform={isOpen ? 'rotate(45deg)' : ''}
                  transition='all 0.2s ease-in-out'
                  position='absolute'
                  top={isOpen ? '0' : '-3px'}
                />
                <Box
                  h='1px'
                  w={isOpen ? '65%' : '40%'}
                  bg='gray.700'
                  transform={isOpen ? 'rotate(-45deg)' : ''}
                  transition='all 0.2s ease-in-out'
                  position='absolute'
                  top={isOpen ? '0' : '3px'}
                />
              </VStack>
            </Center>
            <HStack fontSize='sm' spacing='1' display={{ base: 'none', md: 'flex' }}>
              <Text
                py='3'
                px='4'
                borderRadius='lg'
                border='1px solid'
                borderColor='transparent'
                _hover={{ bg: 'gray.900', borderColor: 'gray.700', textDecoration: 'none' }}
                as={Link}
                href='https://www.resiquant.com/'
              >
                Home
              </Text>
              <Text
                py='3'
                px='4'
                borderRadius='lg'
                border='1px solid'
                borderColor='transparent'
                _hover={{ bg: 'gray.900', borderColor: 'gray.700', textDecoration: 'none' }}
                as={Link}
                href='https://www.resiquant.com/technical-paper'
              >
                Technical Overview
              </Text>
              <Text
                py='3'
                px='4'
                borderRadius='lg'
                border='1px solid'
                borderColor='transparent'
                _hover={{ bg: 'gray.900', borderColor: 'gray.700', textDecoration: 'none' }}
                as={Link}
                href='https://www.resiquant.com/roadmap
'
              >
                Roadmap
              </Text>
              <Button
                textTransform='uppercase'
                fontWeight='light'
                bg='white'
                color='gray.900'
                border='1px solid'
                borderColor='transparent'
                _hover={{
                  bg: 'gray.900',
                  borderColor: 'gray.700',
                  color: 'white',
                  textDecoration: 'none',
                }}
                _active={{}}
                fontSize='sm'
                h='44px'
              >
                Early Access
              </Button>
            </HStack>
          </HStack>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            w='100%'
            align='center'
            pt='8'
            justify={{ base: 'center', lg: 'end' }}
          >
            <Box h='10'>{address && <ConnectButton />}</Box>
          </Stack>
          {isAuthenticated ? (
            <Tabs w='100%' pt='4'>
              <TabList w='100%' textTransform='uppercase' border='1px solid' borderColor='gray.700'>
                <Tab border='1px solid' borderColor='gray.700'>
                  Donate
                </Tab>
                <Tab border='1px solid' borderColor='gray.700'>
                  Airdrop
                </Tab>
                <Tab border='1px solid' borderColor='gray.700'>
                  Tokenomics
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Donate counterValue={counterValue} />
                </TabPanel>
                <TabPanel>
                  <Airdrop />
                </TabPanel>
                <TabPanel>
                  <Tokenomics />
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            <Container maxW='container.lg'>
              <VStack align='start' w='100%' pt='10' pb='20'>
                <Text
                  pb='2'
                  fontSize='5xl'
                  fontWeight='500'
                  letterSpacing='-2px'
                  fontFamily='Aeonik'
                >
                  Early Access
                </Text>
                <VStack w='100%' align='start' bg='whiteAlpha.50' p='5' borderRadius='md'>
                  <VStack w='100%' spacing='4'>
                    <Input
                      w='100%'
                      textAlign='left'
                      border='1px solid'
                      borderColor='gray.800'
                      bg='gray.900'
                      _focusVisible={{ borderColor: 'gray.100' }}
                      value={code}
                      _placeholder={{
                        color: 'whiteAlpha.900',
                      }}
                      onChange={e => setCode(e.target.value)}
                      placeholder='ENTER EARLY ACCESS CODE'
                    />
                    <Button
                      w='100%'
                      bg='white'
                      color='gray.900'
                      fontSize='sm'
                      fontWeight='light'
                      px='12'
                      _active={{}}
                      border='1px solid'
                      borderColor='transparent'
                      _hover={{
                        bg: 'gray.900',
                        borderColor: 'gray.700',
                        color: 'white',
                        textDecoration: 'none',
                      }}
                      onClick={checkCode}
                      textTransform='uppercase'
                    >
                      Enter
                    </Button>
                  </VStack>
                  <Text pt='6' fontSize='sm' color='gray.300' textTransform='uppercase'>
                    Each code is unique and can only be used once
                  </Text>
                </VStack>
              </VStack>
              <VStack align='start' w='100%' pt='0' pb='40'>
                <Text
                  pb='2'
                  fontSize='5xl'
                  fontWeight='500'
                  letterSpacing='-2px'
                  fontFamily='Aeonik'
                >
                  Already Registered?
                </Text>
                <VStack w='100%' align='start' bg='whiteAlpha.50' p='5' borderRadius='md'>
                  <VStack w='100%' spacing='4'>
                    <Button
                      w='100%'
                      bg='white'
                      color='gray.900'
                      fontSize='sm'
                      fontWeight='light'
                      px='12'
                      _active={{}}
                      onClick={openConnectModal}
                      textTransform='uppercase'
                      border='1px solid'
                      borderColor='transparent'
                      _hover={{
                        bg: 'gray.900',
                        borderColor: 'gray.700',
                        color: 'white',
                        textDecoration: 'none',
                      }}
                    >
                      {address ? 'Wallet not registered. Enter access code.' : 'Connect Wallet'}
                    </Button>
                  </VStack>
                </VStack>
              </VStack>
            </Container>
          )}
        </VStack>

        <VStack
          w='100%'
          bg='gray.800'
          border='1px solid'
          borderColor='gray.700'
          p='5'
          borderRadius='lg'
          justify='space-between'
          fontFamily='Aeonik'
          mb='4'
        >
          <SimpleGrid columns={{ base: 2, lg: 4 }} spacing='4' pb='10' w='100%'>
            <VStack align='start' w='100%'>
              <HStack>
                <Image src={ResiquantLogo} alt='R' />
                <Text fontFamily='Aeonik' textTransform='uppercase'>
                  Resiquant
                </Text>
              </HStack>
            </VStack>
            <VStack align='start' w='100%' spacing='-1'>
              <Text fontFamily='Aeonik'>Sitemap</Text>
              <Text
                color='gray.200'
                as={Link}
                href='https://www.resiquant.com'
                _hover={{ color: 'white', textDecoration: 'none' }}
                lineHeight='1.2'
              >
                Home
              </Text>
              <Text
                color='gray.200'
                as={Link}
                href='https://www.resiquant.com/technical-paper'
                _hover={{ color: 'white', textDecoration: 'none' }}
                lineHeight='1.2'
              >
                Technical Overview
              </Text>
              <Text
                color='gray.200'
                as={Link}
                href='https://www.resiquant.com/roadmap'
                _hover={{ color: 'white', textDecoration: 'none' }}
                lineHeight='1.2'
              >
                Roadmap
              </Text>
              <Text
                color='gray.200'
                as={Link}
                href='/'
                _hover={{ color: 'white', textDecoration: 'none' }}
                lineHeight='1.2'
              >
                Early Access
              </Text>
            </VStack>
            <VStack align='start' w='100%' spacing='1'>
              <Text textTransform='capitalize' pb={{ base: '0', md: '4' }}>
                Follow us
              </Text>
              <HStack color='gray.200' spacing='6'>
                <Center
                  as={Link}
                  href='https://discord.com/invite/xh6FufVqGt'
                  target='_blank'
                  _hover={{ color: 'white', textDecoration: 'none' }}
                >
                  <Icon as={FaDiscord} fontSize='3xl' />
                </Center>
                <Center
                  as={Link}
                  href='https://twitter.com/resiquant'
                  _hover={{ color: 'white', textDecoration: 'none' }}
                  target='_blank'
                >
                  <Icon as={BsTwitterX} fontSize='2xl' />
                </Center>
                <Center
                  as={Link}
                  href='https://t.me/resiquant'
                  target='_blank'
                  _hover={{ color: 'white', textDecoration: 'none' }}
                >
                  <Icon as={FaTelegramPlane} fontSize='3xl' />
                </Center>
              </HStack>
            </VStack>
            <VStack align='start' w='100%' spacing='-1'>
              <Text textTransform='capitalize'>Contact us</Text>
              <Text
                color='gray.200'
                as={Link}
                href='mailto:contact@resiquant.com'
                _hover={{ color: 'white', textDecoration: 'none' }}
              >
                contact@resiquant.com
              </Text>
            </VStack>
          </SimpleGrid>
          <HStack
            w='100%'
            justify='space-between'
            color='gray.200'
            borderTop='1px solid'
            borderColor='gray.700'
            pt='2'
            fontFamily='Aeonik'
          >
            <Text>@2024 All Rights reserved</Text>
            <Text
              as={Link}
              href='https://www.resiquant.com/disclaimer'
              _hover={{ color: 'white', textDecoration: 'none' }}
            >
              Disclaimer
            </Text>
          </HStack>
        </VStack>
      </Container>
    </>
  );
};

export default App;
