import { Button } from '@chakra-ui/button';
import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/layout';
import { useAccount, useReadContract, useWriteContract } from 'wagmi';
import { resiquantAbi, donationAddress } from './App';
import { Input } from '@chakra-ui/input';
import { useCallback, useState } from 'react';
import { useToast } from '@chakra-ui/toast';
import { formatEther, parseEther } from 'viem';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import React from 'react';

export default function Donate({ counterValue }: { counterValue: number }) {
  const { address } = useAccount();
  const toast = useToast();

  const [amount, setAmount] = useState('' as string);

  const { data: userDonation = 0 } = useReadContract({
    address: donationAddress,
    abi: resiquantAbi,
    functionName: 'donations',
    args: [address],
  });

  const { data: hasDonated = false } = useReadContract({
    address: donationAddress,
    abi: resiquantAbi,
    functionName: 'hasDonated',
    args: [address],
  });

  const { data: currentRaised = '0' } = useReadContract({
    address: donationAddress,
    abi: resiquantAbi,
    functionName: 'currentRaised',
  });

  const { data: currentPhase = '0' } = useReadContract({
    address: donationAddress,
    abi: resiquantAbi,
    functionName: 'currentPhase',
  });
  const { openConnectModal } = useConnectModal();

  const { writeContractAsync } = useWriteContract();

  const handleDonate = useCallback(async () => {
    try {
      await writeContractAsync({
        abi: resiquantAbi,
        address: donationAddress,
        functionName: 'donate',
        value: parseEther(amount),
      });
    } catch (err) {
      toast({
        title: 'Could not donate',
        status: 'error',
      });
    }
  }, [toast, writeContractAsync, amount]);

  return (
    <VStack w='100%' pt='10' textTransform='uppercase'>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        w='100%'
        justify='space-between'
        borderBottom='1px solid'
        borderColor='gray.700'
        pb='2'
      >
        <HStack w='60'>
          <Text>Batch number:</Text>
          <Text color='gray.300'>1</Text>
        </HStack>
        <HStack>
          <Text>Factor:</Text>
          <Text color='gray.300'>1.75</Text>
        </HStack>
        <HStack w='60' justify={{ base: 'start', lg: 'end' }}>
          <Text>Codes:</Text>
          <Text color='gray.300'>{Math.min(counterValue, 1000)} / 1000</Text>
        </HStack>
      </Stack>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        w='100%'
        justify='space-between'
        borderColor='gray.700'
        pb='1'
      >
        <HStack w='40'>
          <Text color='gray.300'>{formatEther(currentRaised as bigint)} / 1000 ETH</Text>
        </HStack>
        <HStack>
          <Text>Min - Max: 0 - 10 ETH</Text>
        </HStack>
        <HStack w='40'></HStack>
      </Stack>

      <Box py='5' />
      {hasDonated ? (
        <Button
          w='100%'
          bg='white'
          color='gray.900'
          textTransform='uppercase'
          _hover={{}}
          _active={{}}
          fontSize='sm'
        >
          Donation Detected ({formatEther(userDonation as bigint)} ETH)
        </Button>
      ) : address ? (
        <HStack
          justify='center'
          spacing='3'
          border='1px solid'
          borderColor='gray.700'
          p='1.5'
          borderRadius='lg'
        >
          <Input
            w='24'
            border='1px solid'
            borderColor='gray.700'
            bg='gray.800'
            textAlign='center'
            _hover={{ borderColor: 'gray.600' }}
            _focusVisible={{ borderColor: 'gray.600' }}
            _placeholder={{ color: 'gray.400' }}
            value={amount}
            onChange={e => setAmount(e.target.value)}
            placeholder='6.9'
          />

          <Text pr='5'>ETH</Text>
          <Button
            bg='white'
            color='gray.900'
            textTransform='uppercase'
            fontWeight='light'
            border='1px solid'
            borderColor='transparent'
            _hover={{
              bg: 'gray.900',
              borderColor: 'gray.700',
              color: 'white',
              textDecoration: 'none',
            }}
            _active={{}}
            px='16'
            onClick={handleDonate}
          >
            Donate
          </Button>
        </HStack>
      ) : (
        <Button
          bg='white'
          color='gray.900'
          textTransform='uppercase'
          fontWeight='light'
          border='1px solid'
          borderColor='transparent'
          _hover={{
            bg: 'gray.900',
            borderColor: 'gray.700',
            color: 'white',
            textDecoration: 'none',
          }}
          _active={{}}
          w='100%'
          onClick={openConnectModal}
        >
          Connect Wallet To Donate
        </Button>
      )}
      <Box py='10' />

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        w='100%'
        justify='space-between'
        borderBottom='1px solid'
        borderColor='gray.700'
        pb='2'
      >
        <HStack w='60'>
          <Text>Batch number:</Text>
          <Text color='gray.300'>2</Text>
        </HStack>
        <HStack>
          <Text>Factor:</Text>
          <Text color='gray.300'>1.25</Text>
        </HStack>
        <HStack justify={{ base: 'start', lg: 'end' }} w='60'>
          <Text color='gray.300'>First come first served</Text>
        </HStack>
      </Stack>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        w='100%'
        justify='space-between'
        borderColor='gray.700'
        pb='1'
      >
        <HStack w='40'>
          <Text color='gray.300'>
            {currentPhase === 1 ? formatEther(currentRaised as bigint) : '-'} / 3000 ETH
          </Text>
        </HStack>
        <HStack>
          <Text>Min - Max: 0 - 10 ETH</Text>
        </HStack>
        <HStack w='40'></HStack>
      </Stack>
      <Box py='5' />
      <Button
        w='100%'
        bg='whiteAlpha.50'
        border='1px solid'
        borderColor='whiteAlpha.100'
        color='gray.300'
        textTransform='uppercase'
        _hover={{}}
        fontSize='sm'
      >
        Opening Soon
      </Button>
      <Box py='10' />
    </VStack>
  );
}
