import { Image } from '@chakra-ui/image';
import { Container, Text, VStack, Box, Center, HStack, SimpleGrid, Stack } from '@chakra-ui/layout';
import React from 'react';
import Tokenomics1 from './assets/tokenomics1.png';
import Tokenomics2 from './assets/tokenomics2.png';
import Tokenomics3 from './assets/tokenomics3.png';

export default function Tokenomics() {
  return (
    <Container w='100%' maxW='680px'>
      <VStack align='start' w='100%' pt='10' pb='40' fontFamily='Aeonik'>
        <HStack spacing='4' w='100%' borderBottom='2px solid' borderColor='gray.700'>
          <Center
            bg='gray.800'
            border='1px solid'
            borderColor='gray.700'
            boxSize='7'
            borderRadius='6px'
            fontFamily='JetBrains Mono'
            fontSize='sm'
          >
            <Text>1</Text>
          </Center>
          <Text fontSize={{ base: '28px', md: '40px' }} fontWeight='medium'>
            Introduction
          </Text>
        </HStack>
        <Text letterSpacing='0.4px'>
          Integral to our mission of fostering a sustainable and community-driven ecosystem we have
          devised a complex, multi-phase tokenomics structure as follows. Below, we delineate the
          token distribution and operational mechanisms tailored to uphold our core principles of
          transparency, fairness, and active community participation.
        </Text>
        <Box py='4' />

        <HStack spacing='4' w='100%' borderBottom='2px solid' borderColor='gray.700'>
          <Center
            bg='gray.800'
            border='1px solid'
            borderColor='gray.700'
            boxSize='7'
            borderRadius='6px'
            fontFamily='JetBrains Mono'
            fontSize='sm'
          >
            <Text>2</Text>
          </Center>
          <Text fontSize={{ base: '28px', md: '40px' }} fontWeight='medium'>
            Tokenomics Roadmap
          </Text>
        </HStack>

        <Text letterSpacing='0.4px'>
          Resiquant&apos;s tokenomics are strategically divided across two distinct operational
          phases, each with specific mechanisms and objectives tailored to enhance the
          ecosystem&apos;s robustness and community engagement.
        </Text>

        <Box py='1' />
        <HStack align='start' w='100%'>
          <Text>•</Text>
          <Text letterSpacing='0.4px'>
            The Ethereum Mainnet Phase (RESIQ as ERC20): The initial community engagement and
            Resiquant token ($RESIQ) distribution will be done on Ethereum Mainnet through a
            permissionless and equitable framework. A crucial aspect of this era is the
            implementation of a slashing mechanism (i), exclusively active during this phase,
            designed to maintain the ecosystem&apos;s integrity and equitability.
          </Text>
        </HStack>
        <HStack align='start' w='100%'>
          <Text>•</Text>
          <Text letterSpacing='0.4px'>
            The Resiquant Mainnet Phase: Marking the transition to Resiquant&apos;s Mainnet, this
            era signifies the full activation of the blockchain&apos;s capabilities, leveraging the
            unique attributes of the Resiquant Virtual Machine (RVM). Central to the initiation of
            this era is the focus on a token migration alongside the systematic introduction of the
            ecosystem token supply, a move orchestrated in alignment with the blockchain&apos;s
            incentives and security.
          </Text>
        </HStack>

        <Box py='4' />

        <HStack spacing='4' w='100%' borderBottom='2px solid' borderColor='gray.700'>
          <Center
            bg='gray.800'
            border='1px solid'
            borderColor='gray.700'
            boxSize='7'
            borderRadius='6px'
            fontFamily='JetBrains Mono'
            fontSize='sm'
          >
            <Text>3</Text>
          </Center>
          <Text fontSize={{ base: '28px', md: '40px' }} fontWeight='medium'>
            Token Distribution & Mechanics
          </Text>
        </HStack>
        <Box py='4' />
        <Text
          fontSize={{ base: '18px', md: '25px' }}
          pb='1'
          borderBottom='2px solid'
          w='100%'
          borderColor='gray.700'
        >
          Allocation
        </Text>
        <Box py='1' />

        <HStack align='start' w='100%'>
          <Text>•</Text>
          <Text letterSpacing='0.4px'>
            During the ERC20 Phase: Only 40% of the total token supply is deployed on the Ethereum
            Mainnet, with specific allocations designed to engage the community and support the
            ecosystem&apos;s initial development.
          </Text>
        </HStack>
        <HStack align='start' w='100%'>
          <Text>•</Text>
          <Text letterSpacing='0.4px'>
            Transition to Resiquant Mainnet: The remaining 60% of tokens are gradually introduced
            throughout time, enhancing the blockchain&apos;s functionality and community engagement
            opportunities.
          </Text>
        </HStack>

        <br />
        <br />
        <Box py='3' />

        <HStack w='100%' spacing='6'>
          <Text
            fontSize={{ base: '18px', md: '25px' }}
            pb='1'
            borderBottom='2px solid'
            w='100%'
            borderColor='gray.700'
          >
            Resiquant Mainnet
          </Text>

          <Text
            fontSize={{ base: '18px', md: '25px' }}
            pb='1'
            borderBottom='2px solid'
            w='100%'
            borderColor='gray.700'
          >
            Ethereum Mainnet
          </Text>
        </HStack>
        <Box py='3' />
        <Image src={Tokenomics1} alt='Tokenomics' />
        <Box py='3' />
        <VStack
          w='100%'
          align='start'
          spacing='1'
          fontFamily='JetBrains Mono'
          textTransform='uppercase'
          fontSize='sm'
        >
          <Box h='2px' w='100%' bg='gray.700' mb='2' />
          <Stack
            pb='1'
            direction={{ base: 'column', md: 'row' }}
            w='100%'
            justify={{ base: 'start', md: 'space-between' }}
          >
            <Text color='gray.300'>Vesting</Text>
            <Text color='gray.300'>TGE 1st year: 40% of total supply</Text>
          </Stack>
          <Text>Foundation: 5 years</Text>
          <Text>Rewards: 55 years</Text>
          <Box h='2px' w='100%' bg='gray.700' mt='2' mb='5' />
          <SimpleGrid columns={{ base: 2, md: 2 }} spacing='3' w='100%'>
            <HStack w='100%'>
              <Box boxSize='18px' borderRadius='4px' bg='white' />
              <Text color='gray.300' letterSpacing='0.4px'>
                Initial Community Airdrop
              </Text>
            </HStack>
            <HStack w='100%'>
              <Box boxSize='18px' borderRadius='4px' bg='#363636' />
              <Text color='gray.300' letterSpacing='0.4px'>
                Liquidity Pool
              </Text>
            </HStack>
            <HStack w='100%'>
              <Box boxSize='18px' borderRadius='4px' bg='#5D5D5D' />
              <Text color='gray.300' letterSpacing='0.4px'>
                Operational Treasury
              </Text>
            </HStack>
            <HStack w='100%'>
              <Box boxSize='18px' borderRadius='4px' bg='#444CB9' />
              <Text color='gray.300' letterSpacing='0.4px'>
                Foundation
              </Text>
            </HStack>
            <HStack w='100%'>
              <Box boxSize='18px' borderRadius='4px' bg='#767BBC' />
              <Text color='gray.300' letterSpacing='0.4px'>
                Blockchain Rewards
              </Text>
            </HStack>
          </SimpleGrid>
        </VStack>
        <br />
        <br />
        <Box py='5' />
        <Text
          fontSize={{ base: '18px', md: '25px' }}
          pb='1'
          borderBottom='2px solid'
          w='100%'
          borderColor='gray.700'
        >
          Supply Release Schedule
        </Text>
        <Box py='2' />

        <Text fontSize='18px' pb='1' borderBottom='2px solid' w='100%' borderColor='gray.700'>
          Year 1 (40%) - Ethereum Mainnet
        </Text>

        <Text letterSpacing='0.4px'>
          40% of the total $RESIQ supply is released during the first year. This supply will be then
          migrated at the end of year 1 (or when the Resiquant Mainnet is ready) to the Resiquant
          Mainnet.
        </Text>

        <Box py='1' />
        <HStack align='start' w='100%'>
          <Text>•</Text>
          <Text letterSpacing='0.4px'>Initial Community Airdrop - 30%:</Text>
        </HStack>
        <HStack align='start' w='100%' pl='6'>
          <Text>•</Text>
          <Text letterSpacing='0.4px'>
            20%: Unlocked at TGE to promote active community engagement from the outset, including
            donations and airdrops.
          </Text>
        </HStack>
        <HStack align='start' w='100%' pl='6'>
          <Text>•</Text>
          <Text letterSpacing='0.4px'>10%: Gradually unlocked through the Slashing Mechanism*</Text>
        </HStack>

        <HStack align='start' w='100%'>
          <Text>•</Text>
          <Text letterSpacing='0.4px'>
            Operational Treasury - 7%: Facilitates critical operations such as CEX listings,
            marketing, and R&D from the onset, unlocked at TGE.
          </Text>
        </HStack>

        <HStack align='start' w='100%'>
          <Text>•</Text>
          <Text letterSpacing='0.4px'>
            Liquidity Pool - 3%: Ensures market stability and reduced slippage for initial
            transactions, unlocked at TGE.
          </Text>
        </HStack>

        <Box py='4' />

        <Text fontSize='18px' pb='1' borderBottom='2px solid' w='100%' borderColor='gray.700'>
          Year 2-55 (60%) - 100% of Token Supply Release on Resiquant Mainnet
        </Text>

        <br />

        <HStack align='start' w='100%'>
          <Text>•</Text>
          <Text letterSpacing='0.4px'>
            Foundation - 20%: Supports long-term project sustainability, with tokens linearly vested
            over time to align with the foundation&apos;s objectives, during a 5 years peroid.
          </Text>
        </HStack>
        <HStack align='start' w='100%'>
          <Text>•</Text>
          <Text letterSpacing='0.4px'>
            Emissions (Blockchain Rewards) - 40%: Allocated for incentivizing network participation,
            these tokens are vested over 55 years to ensure a stable, low-inflation ecosystem.
          </Text>
        </HStack>

        <br />
        <br />

        <Box py='5' />
        <Text
          fontSize={{ base: '18px', md: '25px' }}
          pb='1'
          borderBottom='2px solid'
          w='100%'
          borderColor='gray.700'
        >
          Slashing Mechanism*
        </Text>

        <Box py='1' />
        <HStack align='start' w='100%'>
          <Text>•</Text>
          <Text letterSpacing='0.4px'>
            Active Exclusively during the Donation period: The slashing mechanism specifically
            targets rewards from the Factor Tokens framework, with a time-dependent curve governing
            the slashing process to maintain the Resiquant ecosystem&apos;s balance and
            sustainability on the Ethereum Mainnet.
          </Text>
        </HStack>
        <Box py='2' />

        <Text letterSpacing='0.4px'>
          The slashing mechanism operates according to the following mathematical function:
        </Text>

        <Center w='100%' px='2' pt='2' pb='16'>
          <Image src={Tokenomics2} alt='Tokenomics' w='100%' maxW={{ md: '400px' }} />
        </Center>

        <Text letterSpacing='0.4px'>
          This mathematical function meticulously defines the decrement in the slashing percentage,
          facilitating a strategic and equitable mechanism for token distribution. In this context,
          the variable x is designated to represent the passage of time, quantified in days, since
          the initiation of the Donation Era. The function&apos;s design inherently encourages
          stakeholders to optimize their reward withdrawals by extending the holding period, thereby
          maximizing the number of tokens retrievable. This approach aligns with a long-term
          strategy within the ecosystem. Accompanied by a graphical depiction, the function allows
          for an intuitive and comprehensive understanding of how the slashing mechanism&apos;s
          impact on rewards evolves over time. This visual representation underscores the gradual
          reduction in slashing percentages, offering holders a clear perspective on optimizing
          their engagement with the ecosystem for maximum benefit.
        </Text>

        <Image pt='6' pb='16' src={Tokenomics3} alt='Tokenomics' />

        <Text
          fontSize={{ base: '18px', md: '25px' }}
          pb='1'
          borderBottom='2px solid'
          w='100%'
          borderColor='gray.700'
        >
          Mainnet Migration
        </Text>
        <Box py='2' />

        <HStack align='start' w='100%'>
          <Text>•</Text>
          <Text letterSpacing='0.4px'>
            Seamless Transition: The migration from the Ethereum Mainnet to the Resiquant Mainnet is
            meticulously planned to ensure a smooth transition.
          </Text>
        </HStack>

        <Box py='6' />

        <HStack spacing='4' w='100%' borderBottom='2px solid' borderColor='gray.700'>
          <Center
            bg='gray.800'
            border='1px solid'
            borderColor='gray.700'
            boxSize='7'
            borderRadius='6px'
            fontFamily='JetBrains Mono'
            fontSize='sm'
          >
            <Text>4</Text>
          </Center>
          <Text fontSize={{ base: '28px', md: '40px' }} fontWeight='medium'>
            Conclusion
          </Text>
        </HStack>

        <Text letterSpacing='0.4px'>
          Resiquant&apos;s tokenomics framework is designed with the utmost consideration for
          community engagement, sustainability, and long-term growth. By detailing the operational
          phases, token distribution strategies, and the slashing mechanism of the Donation Era, we
          aim to provide a transparent and comprehensive overview of the ecosystem&apos;s economic
          model. For more information or to join our community, please reach out via
          contact@resiquant.com or our official Discord channel.
        </Text>
      </VStack>
    </Container>
  );
}
