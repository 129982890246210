import '@rainbow-me/rainbowkit/styles.css';
import './polyfills';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { darkTheme, getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import { mainnet, sepolia } from 'wagmi/chains';

import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';

export const config = getDefaultConfig({
  appName: 'RESIQUANT',
  projectId: 'YOUR_PROJECT_ID',
  chains: [mainnet, sepolia],
});

const myTheme = {
  fonts: {
    heading: `'JetBrains Mono', monospace`,
    body: `'JetBrains Mono', monospace`,
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  components: {
    Button: {
      baseStyle: {
        letterSpacing: '1px',
        borderRadius: '7px',
      },
    },
    Text: {
      baseStyle: {
        letterSpacing: '0.7px',
      },
    },
    Tabs: {
      defaultProps: {
        variant: 'primary',
        bg: 'red',
      },
      baseStyle: {
        tab: {},
        tablist: {
          borderRadius: '8px',
          p: '2',
          mb: '4',
          bg: 'gray.800',
          display: 'inline-flex',
        },
        tabpanel: {
          p: '0',
        },
      },
      variants: {
        primary: {
          tab: {
            '&&': {
              position: 'relative',
              fontSize: { base: 'sm', md: 'sm' },
              fontWeight: 'medium',
              textTransform: 'uppercase',
              color: 'whiteAlpha.700',
              borderRadius: '8px',
              bg: 'gray.900',
              _selected: {
                bg: 'white',
                color: 'gray.900',
                _hover: { color: 'gray.900' },
              },
              _hover: { color: 'white' },
              _focus: { boxShadow: 'none' },
              width: '100%',
              mx: '1',
              py: '2',
              letterSpacing: '0.3px',
              transition: 'all 0.14s ease-in-out 0s',
              ':first-of-type': {
                ml: '0',
              },
              ':last-of-type': {
                mr: '1',
              },
            },

            tabpanels: {
              p: '0',
            },
            tabpanel: {
              w: '100%',
              h: '100%',
              p: '0',
            },
          },
        },
      },
    },
  },
  colors: {
    white: '#e8e8e8',
    brand: {
      900: '#1a365d',
      800: '#153e75',
      700: '#2a69ac',
    },
    gray: {
      900: '#171616',
      800: '#1e1e1e',
      700: '#2b2b2b',
      600: '#3a3a3a',
      500: '#4a4a4a',
      400: '#6a6a6a',
      300: '#7a7a7a',
      200: '#8a8a8a',
      100: '#9a9a9a',
      50: '#aaaaaa',
    },
  },
  styles: {
    global: {
      'html, body': {
        margin: 0,
        padding: 0,
        bg: 'gray.900',
        color: '#e8e8e8',
      },
    },
  },
};

const theme = extendTheme(myTheme);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <ChakraProvider resetCSS={true} theme={theme}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            appInfo={{
              appName: 'Resiquant',
              learnMoreUrl: 'https://resiquant.com',
              disclaimer: () => <div>Disclaimer</div>,
            }}
            theme={darkTheme({
              accentColor: '#444444',
              accentColorForeground: 'white',
              borderRadius: 'medium',
              fontStack: 'system',
              overlayBlur: 'small',
            })}
          >
            <App />
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
