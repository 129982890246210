import { Text, Box, VStack } from '@chakra-ui/layout';

import React from 'react';
import { Button, HStack } from '@chakra-ui/react';
import { useAccount, useReadContract } from 'wagmi';
import { donationAddress, resiquantAbi } from './App';
import { formatEther } from 'viem';
import BigNumber from 'bignumber.js';

export default function Airdrop() {
  const { address } = useAccount();

  const { data: userDonation = 0 } = useReadContract({
    address: donationAddress,
    abi: resiquantAbi,
    functionName: 'donations',
    args: [address],
  });

  return (
    <VStack w='100%' pt='10' pb='40'>
      <VStack align='start' w='100%' textTransform='uppercase'>
        <HStack w='60'>
          <Text>Donation:</Text>
          <Text color='gray.300'>{formatEther(userDonation as bigint)} ETH</Text>
        </HStack>
        <Box h='1px' w='100%' bg='gray.700' />
        <HStack>
          <Text>Factor:</Text>
          <Text color='gray.300'>1.75</Text>
        </HStack>
      </VStack>

      <VStack pt='20' align='start' w='100%' textTransform='uppercase'>
        <Box h='1px' w='100%' bg='gray.700' />
        <HStack>
          <Text>Total $RESIQ:</Text>
          <Text color='gray.300'>
            {new BigNumber(formatEther(userDonation as bigint)).times(1.75).toFixed()}
          </Text>
        </HStack>
      </VStack>

      <VStack pt='20' align='start' w='100%' textTransform='uppercase'>
        <Box h='1px' w='100%' bg='gray.700' />
        <HStack>
          <Text color='gray.300'>Hash: Ethereum</Text>
        </HStack>
      </VStack>

      <Box py='5' />
      <Button
        w='100%'
        bg='whiteAlpha.50'
        border='1px solid'
        borderColor='whiteAlpha.100'
        color='gray.300'
        textTransform='uppercase'
        _hover={{}}
        fontSize='sm'
      >
        Claim
      </Button>
    </VStack>
  );
}
